import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServicesSection = () => {


  const [items, setItems] = useState([]);
  const [service, setService] = useState([]);

  useEffect(() => {
    fetch(`https://packagingseoclone17-690986e09c94.herokuapp.com/service-items`)
      .then((res) => res.json())
      .then((info) => setItems(info));
  }, []);


  useEffect(() => {
    fetch(`https://packagingseoclone17-690986e09c94.herokuapp.com/service`)
      .then((res) => res.json())
      .then((info) => setService(info));
  }, []);


  return (



    <>

      <section id="service" className="service-2 section-space overflow-hidden parallax-element">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {
                service.map(e=><div className="section-3__title-wrapper service-2__content text-center mb-60 mb-sm-50 mb-xs-40">
                  <span className="section-3__subtitle justify-content-center mb-10 mb-xs-5 wow fadeIn animated" data-wow-delay=".1s"><span className="layer" data-depth="0.009">{e.servicesubHeading}</span> for Customers</span>
                  <h2 className="section-3__title lg wow fadeIn animated" data-wow-delay=".3s">{e.serviceHeading}</h2>
                </div> )
              }

              
            </div>
          </div>
          <div className="row gx-60 mb-minus-60">
            {
              items.map(i => <div className="col-xl-4 col-md-6">
                <div className="service-2__item mb-60 wow fadeIn animated" data-wow-delay=".5s">
                  <div className="service-2__item-circle">
                    <span />
                    <span />
                    <span />
                  </div>
                  <div className="service-2__item-icon mb-35 mb-sm-30 mb-xs-25">
                    <img src={i.serviceIcon} alt="icon not found" />
                  </div>
                  <h4 className="mb-10 mb-xs-5">{i.serviceTitle}</h4>
                  <p className="mb-30">{i.serviceDetails}</p>

                </div>
              </div>)
            }



          </div>
          <div className="row">

          </div>
        </div>
      </section>

    </>


  );
};

export default ServicesSection;
