// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCV3yknDnz20T0NmsKsPCQaA6u1JwQWhLo",
  authDomain: "packagingseoclone17.firebaseapp.com",
  projectId: "packagingseoclone17",
  storageBucket: "packagingseoclone17.firebasestorage.app",
  messagingSenderId: "1041941897145",
  appId: "1:1041941897145:web:c80efda3e4f483a162e068"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;